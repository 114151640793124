<!-- System: STA
    Purpose: This compoment will display team members
            table in list view
-->
<template>
  <v-app>
    <template>
      <!-- Team Member Table -->
      <v-simple-table class="mt-4">
        <template v-if="filtered.length > 0" v-slot:default>
          <thead style="padding-bottom: 100px">
            <tr>
              <th class="text-center"></th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('name')"
              >
                Name <span class="mdi mdi-sort" style="font-size: 22px"></span>
              </th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('working_on')"
              >
                Working On
                <span class="mdi mdi-sort" style="font-size: 22px"></span>
              </th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('today_minutes')"
              >
                Today Minutes
                <span class="mdi mdi-sort" style="font-size: 22px"></span>
              </th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('weekly_minutes')"
              >
                Weekly Minutes
                <span class="mdi mdi-sort" style="font-size: 22px"></span>
              </th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('monthly_minutes')"
              >
                Monthly Minutes
                <span
                  class="mdi mdi-sort"
                  style="font-size: 22px; cursor: pointer"
                ></span>
              </th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="member in filtered"
              :key="member.id"
              class="member_table_row"
              :class="member.display == false ? 'dom-render' : ''"
            >
              <td>
                <!-- Tooltip For Team Member Details -->
                <v-tooltip class="uner_info_tooltip" top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      v-if="member.image == null"
                      src="../../assets/images/no-member.jpg"
                      @error="
                        $event.target.src = require('../../assets/images/no-member.svg')
                      "
                      alt="no member"
                      class="member-image mt-5"
                      v-bind="attrs"
                      v-on="on"
                      fluid
                      thumbnail
                    />
                    <img
                      v-else
                      :id="'popover_' + member.id"
                      :hover="(displayPopover = true)"
                      :src="`${url}storage/${member.image}`"
                      @error="
                        $event.target.src = require('../../assets/images/no-member.svg')
                      "
                      alt="Thumbnail"
                      class="member-image mt-5"
                      fluid
                      thumbnail
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span
                    ><UserInfo :member="userInfo(member.id)"></UserInfo
                  ></span>
                </v-tooltip>
                <!-- Tooltip For Team Member Status -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-badge
                        class="badge-status"
                        :color="
                          memberStatus(member) === 'online'
                            ? '#1ce21c'
                            : memberStatus(member) === 'idle'
                            ? '#f7b12e'
                            : member.is_active &&
                              (member.today_minutes === '0' ||
                                !member.today_minutes)
                            ? 'red'
                            : member.is_active && member.today_minutes
                            ? '#17a2b8'
                            : ''
                        "
                        dot
                      >
                      </v-badge>
                    </div>
                  </template>
                  <span>
                    {{
                      memberStatus(member) === "online"
                        ? "Online"
                        : memberStatus(member) === "idle"
                        ? "Idle"
                        : member.is_active &&
                          (member.today_minutes === "0" ||
                            !member.today_minutes)
                        ? "Absent"
                        : member.is_active && member.today_minutes
                        ? "Worked"
                        : ""
                    }}
                  </span>
                </v-tooltip>
              </td>
              <td v-if="selectedCompanyUserStatus != 'employee'">
                <v-edit-dialog
                  :return-value.sync="member.name"
                  @save="updateMemberName(member)"
                  @open="open(member)"
                >
                  {{ member.name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="name"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td v-else>{{ member.name }}</td>
              <td>{{ activeProject(member) }}</td>
              <td
                @click="goToWorkDiary(member, member.today_minutes)"
                style="cursor: pointer"
              >
                {{ convert_mins_to_hour(member.today_minutes) }}
              </td>
              <td>{{ convert_mins_to_hour(member.weekly_minutes) }}</td>
              <td>{{ convert_mins_to_hour(member.monthly_minutes) }}</td>
              <!-- Team Member Action Buttons -->
              <td>
                <!-- Edit User Info Button -->
                <v-btn
                  text
                  x-small
                  @click="showUserInfoModal(member)"
                  v-if="selectedCompanyUserStatus !== 'employee'"
                >
                  <v-icon class="icon-color" data-trigger="edit_profile_member">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  text
                  small
                  style="display: none"
                  v-if="selectedCompanyUserStatus !== 'employee'"
                >
                  <v-icon class="icon-color"> mdi-ballot-outline </v-icon>
                </v-btn>
                <!-- Team Member Table Sub Menu -->
                <v-menu offset-y :close-on-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <!-- Submenu Button -->
                    <v-btn
                      text
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="socketMember = member"
                    >
                      <v-icon
                        class="roles-dropdown-icon icon-color"
                        elevation="0"
                      >
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <!-- Submenu List dropdown -->
                  <v-list class="text-left">
                    <!-- Assign Project Button -->
                    <v-list-item
                      dense
                      v-show="!member.silent_user"
                      v-if="selectedCompanyUserStatus !== 'employee'"
                      class="text-left"
                    >
                      <v-list-item-title class="text-left">
                        <v-btn
                          class="small-drop-down"
                          small
                          color="#2758f6"
                          outlined
                          style="background: white"
                          @click="assignProjects(member)"
                          >Assign Projects
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Assign Team Member Button -->
                    <v-list-item
                      dense
                      v-show="!member.silent_user"
                      v-if="selectedCompanyUserStatus !== 'employee'"
                    >
                      <v-list-item-title
                        ><v-btn
                          small
                          class="small-drop-down"
                          color="#2758f6"
                          outlined
                          style="background: white"
                          @click="assignTeamMembers(member)"
                          >Assign Team Members</v-btn
                        ></v-list-item-title
                      >
                    </v-list-item>
                    <!-- Instant Capture Button -->
                    <v-list-item dense>
                      <v-list-item-title
                        ><v-btn
                          small
                          color="#2758f6"
                          :disabled="!checkSocket"
                          outlined
                          style="background: white"
                          class="small-drop-down"
                          @click="instant_capture_req(member)"
                          >Instant Capture
                        </v-btn></v-list-item-title
                      >
                    </v-list-item>
                    <!-- Real Time Monitoring Button -->
                    <v-list-item
                      dense
                      v-if="
                        selectedCompany.is_real_time_allowed &&
                        member.super_secure_user !== 'SUPERSECUREUSER'
                      "
                    >
                      <v-list-item-title
                        ><v-btn
                          small
                          color="#2758f6"
                          :disabled="!checkSocket"
                          outlined
                          style="background: white"
                          class="small-drop-down"
                          @click="real_time_req(member)"
                          >Real Time Monitoring
                        </v-btn></v-list-item-title
                      >
                    </v-list-item>
                    <!-- Face Registration Button -->
                    <v-list-item
                      dense
                      v-if="
                        !member.silent_user &&
                        member.member_status === 'active' &&
                        selectedCompanyUserStatus !== 'employee' &&
                        FaceRegistrationAllowed
                      "
                    >
                      <v-list-item-title
                        ><v-btn
                          small
                          color="#2758f6"
                          outlined
                          style="background: white"
                          class="small-drop-down"
                          @click="faceRegistrationTeamMember(member)"
                          >Face Registration
                        </v-btn></v-list-item-title
                      >
                    </v-list-item>
                    <!-- Go To Reports Button -->
                    <v-list-item dense>
                      <v-list-item-title
                        v-if="selectedCompanyUserStatus !== 'employee'"
                        ><v-btn
                          small
                          color="#2758f6"
                          outlined
                          style="background: white"
                          class="small-drop-down"
                          @click="go_to_report(member)"
                          >Go to Reports
                        </v-btn></v-list-item-title
                      >
                    </v-list-item>
                    <!-- Archive User Button -->
                    <v-list-item
                      dense
                      v-if="
                        member.member_status === 'active' &&
                        selectedCompanyUserStatus !== 'employee'
                      "
                    >
                      <v-list-item-title
                        ><v-btn
                          small
                          color="#2758f6"
                          outlined
                          style="background: white"
                          class="small-drop-down"
                          @click="
                            (archiveMemberModal = true),
                              (memberToArchive = member)
                          "
                          >Archive User
                        </v-btn></v-list-item-title
                      >
                    </v-list-item>

                    <!-- Upload profile -->
                    <v-list-item dense v-if="selected_company.additional_features_allowed && selectedCompanyUserStatus !== 'employee'">
                      <v-list-item-title>
                        <v-btn
                          small
                          color="#2758f6"
                          outlined
                          style="background: white"
                          class="small-drop-down"
                          @click="openFileInput(), (memberToChangeImage = member)"
                          >Upload Profile Image
                          <v-file-input
                          accept="image/*"
                          id="upload_img"
                          ref="fileInput"
                          label="File input"
                          style="display: none;"
                          @change="handleFileChange"
                          ></v-file-input>
                          
                        </v-btn></v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
        <!-- Render If No Member Found -->
        <template v-else v-slot:default>
          <div class="col-md-12 mt-4">
            <img
              class="error-img"
              src="../../assets/images/no-activity-found-new.svg"
            />
            <p class="error_text">
              {{
                $store.state.custom.activeLoader == true
                  ? "...loading"
                  : "No Member Found"
              }}
            </p>
          </div>
        </template>
      </v-simple-table>
    </template>
    <!-- Archive Member Dialog Box / Modal-->
    <v-dialog
      v-model="archiveMemberModal"
      width="500"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar color="primary" class="headline" dark
          >Are you sure?</v-toolbar
        >
        <v-card-text class="mt-4">
          <h4>
            If you archive this team member, he will temporarily be removed from
            all the projects and status will be changed to inactive.
          </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="(archiveMemberModal = false), (memberToArchive = '')"
          >
            Cancel
          </v-btn>
          <v-btn color="error" outlined text @click="move_to_archive()">
            Archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import _ from "lodash";
import { min_to_hour } from "@/assets/js/utils";
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";
import UserInfo from "../includes/UserInfo";
export default {
  components: {
    UserInfo,
  },
  props: {
    members: {},
    membersToShow: {},
  },
  data() {
    return {
      name: "",
      snackColor: "red",
      snackText: "",
      renderRemainingMembers: false,
      archiveMemberModal: false,
      socketMember: "",
      memberToArchive: "",
      memberToChangeImage:"",
      filters: {
        name: "",
      },
      order_by: {
        col: "today_minutes",
        selectedOrder: "desc",
      },
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("custom", [
      "selected_company",
    ]),
    /**
     * Return the selected company
     */
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
    /**
     * Return if member is
     * currently online
     */
    checkSocket() {
      if (this.socketMember && this.socket_employee)
        return !!this.socket_employee.find(
          (user) => user.user_id === this.socketMember.id
        );
    },
    selectedCompanyUserStatus() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    FaceRegistrationAllowed() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.facial_attendance_allowed;
      else {
        return false;
      }
    },
    /**
     * Return is team lead status
     */
    teamLeadStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
    },
    /**
     * Return all socket employee from store
     */
    socket_employee() {
      return this.$store.state.custom.all_store_socket_employee;
    },
    /**
     * Return base url of api
     */
    url() {
      return baseURL.API_URL;
    },
    /**
     * Return filterd member
     * according to column
     */
    filtered() {
      for (let member of this.members) {
        var mem = this.socket_employee.find((user) => {
          return user.user_id == member.id;
        });
        member.working_on = mem ? (mem.project ? mem.project : null) : null;
      }
      let filteredMembers = this.members;
      if (this.order_by.col == "weekly_minutes") {
        filteredMembers = _.orderBy(
          this.members,
          [
            function (o) {
              return +o.weekly_minutes;
            },
          ],
          [this.order_by.selectedOrder]
        );
      }
      if (this.order_by.col == "today_minutes") {
        filteredMembers = _.orderBy(
          this.members,
          [
            function (o) {
              return +o.today_minutes;
            },
          ],
          [this.order_by.selectedOrder]
        );
      }
      if (this.order_by.col == "monthly_minutes") {
        filteredMembers = _.orderBy(
          this.members,
          [
            function (o) {
              return +o.monthly_minutes;
            },
          ],
          [this.order_by.selectedOrder]
        );
      }
      if (this.order_by.col == "name" || this.order_by.col == "working_on") {
        filteredMembers = _.orderBy(
          this.members,
          [this.order_by.col],
          [this.order_by.selectedOrder]
        );
      }
      return filteredMembers;
    },
  },
  sockets: {
    real_time_monitoring(image, user_id) {
      this.$root.$emit("real_time_monitor", image, user_id);
    },
  },
  methods: {
    /**
     * This function is resposible for
     * pass the data to register team
     * member face in modal component
     */
    faceRegistrationTeamMember(member) {
      this.$eventBus.$emit("faceRegistration", member);
    },
    /**
     * This function is resposible for
     * copy previous name into edit field
     */
    open(member) {
      this.name = member.name;
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    /**
     * This function is resposible for
     * update the member name from table
     */
    updateMemberName(member) {
      let newName = this.name;
      let data = {
        company_id: this.$route.params.id,
        member_id: member.id,
        name: newName,
      };
      this.$store
        .dispatch("custom/update_member_name", data)
        .then((response) => {
          this.$store.commit("custom/toggle_progress_loader", false);
          if (response.data.error === false) {
            for (let i = 0; i < this.filtered.length; i++) {
              if (this.filtered[i].id == member.id) {
                this.filtered[i].name = newName;
              }
            }
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.name = "";
          }
        })
        .catch(() => {
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Error During Updating Name",
            snackbarColor: "green",
          });
          this.$store.commit("custom/toggle_progress_loader", false);
        });
    },
    /**
     * This function is resposible for
     * returning member status
     * e.g online , idle etc
     */
    memberStatus(memberCheck) {
      if (!memberCheck || !this.socket_employee) return "";
      let member = this.socket_employee.find(
        (user) => user.user_id === memberCheck.id
      );
      if (!member) return "";

      let pro = member.hasOwnProperty("task_running");
      return pro ? (member.task_running ? "online" : "idle") : "online";
    },
    /**
     * This function is resposible for
     * returning member data
     */
    userInfo(id) {
      let member = this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == id
      );
      if (member) {
        return member;
      } else {
        return [];
      }
    },
    /**
     * This function is resposible for
     * pass the member data to edit
     * user info modal component
     */
    showUserInfoModal(member) {
      this.$eventBus.$emit("editUserInfo", member);
    },
    /**
     * This function is resposible for
     * pass the data to assign team
     * member modal component
     */
    assignTeamMembers(member) {
      this.$eventBus.$emit("assignTeamMembers", member);
    },
    /**
     * This function is resposible for
     * pass the data to assign project
     * modal component
     */
    assignProjects(member) {
      this.$eventBus.$emit("assignProjects", member);
    },
    /**
     * This function is resposible for
     * move the member to reports page
     */
    go_to_report(member) {
      this.$store.commit("custom/set_report_member_id", member.id);
      let routeData = this.$router.resolve({
        name: "Reports",
        params: { id: this.$route.params.id },
      });
      window.open(routeData.href, "_blank");
    },
    /**
     * This function is resposible for
     * move to member in archived list
     * api call
     */
    move_to_archive() {
      if (this.memberToArchive.member_status === "archive") {
        return;
      }
      let data = {
        member_id: this.memberToArchive.id,
        company_id: this.$route.params.id,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/archive_member", data)
        .then((response) => {
          this.$store.dispatch("custom/get_archived_team_members_list", data);
          this.$store.commit("custom/toggle_loader", false);
          this.archiveMemberModal = false;
          if (response.data.error === false) {
            this.$store.commit("custom/set_loader", false);
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.$socket.emit(
              "archive_member",
              this.member.id + "_" + this.$route.params.id
            );
            this.$root.$emit("snack_bar", {
              show: true,
              message: "You have successfully archived a member.",
              snackbarColor: "green",
            });
            this.memberToArchive = "";
          }
        })
        .catch((error) => {
          this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    /**
     * This function is resposible for
     * instant capture.
     * pass data to instant capture modal
     */
    instant_capture_req(member) {
      this.$store.commit("custom/toggle_loader", true);
      let member_id = member.id;
      let user_id = this.user.id;
      let auth_token = this.user.auth_token;
      this.$root.$emit("open_modal", member_id);
      this.$socket.emit(
        "instant capture",
        {
          user_id,
          member_id,
          company_id: this.$route.params.id,
          auth_token,
        },
        (response, user_id) => {
          this.$root.$emit("send_member_id", member_id);
          this.$root.$emit("instant_capture", response, user_id);
        }
      );
    },
    /**
     * This function is resposible for
     * real time monitoring.
     * pass data to eal time monitoring modal.
     */
    real_time_req(member) {
      let member_id = member.id;
      let company_id = this.$route.params.id;
      let user_id = this.user.id;
      let memberid = member_id;
      let auth_token = this.user.auth_token;
      this.$root.$emit("real_send_member_id", member_id);
      this.$socket.emit("real_time_monitoring", {
        user_id,
        memberid,
        company_id,
        auth_token,
      });
    },
    /**
     * This function is resposible for
     * converting mins to hours
     */
    convert_mins_to_hour(workintTime) {
      let time = min_to_hour(workintTime);
      let hour = time.hrs < 10 ? "0" + time.hrs : time.hrs;
      let mins = time.mins < 10 ? "0" + time.mins : time.mins;
      return hour + ":" + mins;
    },
    /**
     * This function is resposible for
     * returning member active project
     */
    activeProject(members) {
      if (!members || !this.socket_employee) return "";
      let member = this.socket_employee.find(
        (user) => user.user_id === members.id
      );
      return member && member.project ? member.project : "";
    },
    /**
     * This function is resposible for
     * sort column data
     */
    sortData(col) {
      this.order_by.selectedOrder =
        this.order_by.selectedOrder == "desc" ? "asc" : "desc";
      this.order_by.col = col;
    },
    /**
     * This function is resposible for
     * getting total time of in
     * required format
     */
    getTotalTime(time) {
      let data = JSON.parse(time);
      let hours = 0,
        minutes = 0;
      Object.values(data).forEach((v) => {
        if (v) {
          let time = v.split(":");
          hours = hours + parseInt(time[0]);
          minutes = minutes + parseInt(time[1]);
        }
      });

      hours = Math.trunc(hours + minutes / 60);
      minutes = minutes % 60;
      if (hours.toString().length < 2 || minutes.toString().length < 2) {
        if (minutes.toString().length < 2 && hours.toString().length < 2) {
          return `0${hours}:0${minutes}`;
        }
        if (minutes.toString().length < 2 && !hours.toString().length < 2) {
          return `${hours}:0${minutes}`;
        }
        if (!minutes.toString().length < 2 && hours.toString().length < 2) {
          return `0${hours}:${minutes}`;
        }
      }
      return `${hours}:${minutes}`;
    },
    /**
     * This function is resposible for rendering
     * member after 1500ms in table
     */
    renderOtherMembers() {
      setTimeout(() => {
        this.renderRemainingMembers = true;
      }, 1500);
    },
    /**
     * Current Date workdairy check.
     */
    goToWorkDiary(member, seconds) {
      if (seconds >= 1) {
        if (member.member_status !== "active") return;
        this.$store.commit("custom/set_diary_member_id", member.id);
        let routeData = this.$router.resolve({
          name: "WorkDiary",
          params: { id: this.$route.params.id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    openFileInput() {
      document.getElementById('upload_img').click()
    },
    handleFileChange(file) {
      this.updateMemberImage(file, this.memberToChangeImage.id)
    },
    updateMemberImage(selectedFile, member_id) {
      this.$store.commit("custom/toggle_progress_loader", true);
      let formData = new FormData();
      // company_id: this.$route.params.id,
      formData.append('member_id', member_id);
      formData.append('company_id', this.$route.params.id);
      formData.append('image', selectedFile);
      this.$store
        .dispatch("custom/update_member_image", formData)
        .then((response) => {
          document.getElementById('upload_img').value = '';
          this.memberToChangeImage.image = response.data.user.image
          this.$store.commit("custom/toggle_progress_loader", false);
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.name = "";
          }
        })
        .catch(() => {
          document.getElementById('upload_img').value = '';
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Error During Updating image",
            snackbarColor: "red",
          });
          this.$store.commit("custom/toggle_progress_loader", false);
        });
    },
  },
  mounted() {
    this.renderOtherMembers();
  },
};
</script>

<style scoped>
.dom-render {
  display: none;
}
img.member-image {
  border-radius: 100%;
  max-width: 65px;
  max-height: 40px;
  margin-left: -2px !important;
}
span.v-badge.badge-status.v-badge--dot.theme--light {
  position: relative !important;
  top: -20px !important;
  left: 12px !important;
}
</style>
<style>
.small-drop-down {
  width: 190px;
}
tr.member_table_row {
  background-color: #fff;
  line-height: 1 !important;
}
tr.member_table_row td {
  border-bottom: 1px solid #cbdaf2 !important;
}
table {
  border-collapse: separate;
  border-spacing: 0 10px !important;
  background-color: unset !important;
}
.theme--light.v-data-table {
  background-color: unset !important;
}
.deactivate-acc {
  max-width: 1100px;
  color: #88a1ca;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin: auto;
}

.deactivate-acc h5 {
  font-size: 18px;
  font-weight: 600;
  color: #36454f;
  white-space: nowrap;
}

.deactivate-acc a {
  color: #2758f6;
}

.start-camera h3 {
  font-size: 20px;
  text-align: center;
}
@media (max-width: 1024px) {
  .card-body .v-form-group {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .card {
    width: 100% !important;
  }
}
@media (max-width: 601px) {
  .setting-main-div .card-body .v-form-group {
    padding-left: 0px !important;
  }
  .save-setting-btn {
    width: 30% !important;
  }
  .save-setting-btn {
    margin-right: 0px !important;
  }
  .save-setting-btn {
    width: 71% !important;
    margin-right: 13px !important;
    height: 36px;
    font-size: 17px;
  }
}
@media (max-width: 480px) {
  .btn-row .save-setting-btn {
    width: 40% !important;
  }
}
@media (max-width: 375px) {
  .btn-row .save-setting-btn {
    width: 40% !important;
    height: 35px;
    margin-bottom: 8px;
    font-size: 16px;
  }
}
.img-col {
  margin: inherit;
}

.my-btn-img {
  height: 32px;
  border: 0.75px solid #2758f6;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.23);
  color: #2758f6;
  margin-bottom: 0rem !important;
  font-size: 14px;
  white-space: nowrap;
  padding: 0px 10px;
}

.my-btn-img:hover {
  color: #2758f6;
}
</style>
